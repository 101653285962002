import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  NgxMaskDirective,
  NgxMaskPipe,
  provideNgxMask,
} from "ngx-mask";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerModule,
} from "@ng-bootstrap/ng-bootstrap";

import { DefaultDatepickerComponent } from "./default-datepicker/default-datepicker.component";
import { StandardDatepickerComponent } from "./standard-datepicker/standard-datepicker.component";
import { DatepickerComponent } from "./datepicker.component";
import { StandardDatepickerModelAdapter } from "./adapters/standard-datepicker-model.adapter";
import { StandardDatepickerFormatter } from "./adapters/standard-datepicker-formatter.adapter";

@NgModule({
  declarations: [
    DatepickerComponent,
    DefaultDatepickerComponent,
    StandardDatepickerComponent,
  ],
  imports: [
    CommonModule,
    NgbDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  exports: [DatepickerComponent],
  providers: [

    provideNgxMask(),
  ],
})
export class DatepickerModule {}
